import Analytics from "../../lib/analytics/Analytics";
import { getGDCPageEvents } from "../../lib/analytics/AnalyticsEvents";
import useIsMobile from "../../lib/customHooks/isMobile";
import CustomImageComponent from "../common/CustomImage/CustomImageComponent";
import GDCSubmitButton from "../common/FixedDownloadButton/GDCSubmitButton";
import Marquee from "../common/Marquee/Marquee";
import styles from "./BannerComponent.module.css";
import parse from "html-react-parser"

const BannerComponent = (props) => {
    const { isMobileDevice } = useIsMobile();

    const bannerData = props;
    const sendAnalyticsGDCHedaer = () => {
      Analytics.getInstance().logEvent(getGDCPageEvents('Header_Submit_Clicked'));
    }

    return (
      <div className={styles.mainContainer}>
        <div className={styles.bannerContainer}>
          <CustomImageComponent
            layout="fill"
            objectFit="cover"
            alt={"banner"}
            src={isMobileDevice ? bannerData.bgImgMobile : bannerData.bgImg}
          />
        </div>
        <div className="container">
          <div className={styles.waterMarkAndTextContainer}>
            <div className={styles.waterMarkNoWrap}>{bannerData.watermark}</div>
            <div className={styles.bannerHeading}>
              {parse(bannerData.heading)}
            </div>
          </div>
          <div className={styles.bannerLogo}>
            <CustomImageComponent
              layout="fill"
              objectFit="contain"
              alt={"banner"}
              src={bannerData.logo}
            />
          </div>
          <div className={styles.bannerButton}>
            <GDCSubmitButton
              text={bannerData.buttonData.text}
              url={bannerData.buttonData.url}
              sendAnalytics={sendAnalyticsGDCHedaer}
            />
          </div>
        </div>
        <div className={styles.partnerContainer}>
          <div className="container">
            <div className={styles.partnerHeading}>{bannerData.partnersData.heading}</div>
            {isMobileDevice ? (
              <div className={styles.partnerLogoContainer}>
                <Marquee>
                  {bannerData.partnersData.logo.map((item) => {
                    return (
                      <div className={styles.partnerLogo}>
                        <CustomImageComponent
                          layout="fill"
                          objectFit="contain"
                          objectPosition={"center"}
                          alt={"partnerLogo"}
                          src={item.src}
                        />
                      </div>
                    );
                  })}
                </Marquee>
              </div>
            ) : (
              <div className={styles.partnerLogoContainer}>
                {bannerData.partnersData.logo.map((item) => {
                  return (
                    <div className={styles.partnerLogo}>
                      <CustomImageComponent
                        layout="fill"
                        objectFit="contain"
                        objectPosition={"center"}
                        alt={"partnerLogo"}
                        src={item.src}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

export default BannerComponent;